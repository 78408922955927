import * as Sentry from '@sentry/browser'

const tag = 'PACK'
const colors = {
    [Sentry.Severity.Debug]: '#77f',
    [Sentry.Severity.Info]: '#4f4',
    [Sentry.Severity.Log]: '#4f4',
    [Sentry.Severity.Warning]: '#e94',
    [Sentry.Severity.Error]: '#f44',
    [Sentry.Severity.Fatal]: '#f44',
    [Sentry.Severity.Critical]: '#f44',
}

const getStyle = (color: string) => `
    color: black;
    background: ${color};
    padding: 1px 10px;
    border-radius: 20px;
    font-weight: bold;
`

const override =
    typeof window !== 'undefined'
        ? window.localStorage.getItem('verbose') === 'v'
        : true

function log(severity: Sentry.Severity, message: string, ...props: any) {
    if (process.env.NODE_ENV === 'development' || override) {
        console.log(
            `%c${tag} | ${severity.toUpperCase()}`,
            getStyle(colors[severity]),
            message,
            ...props,
        )
        return
    }
    // Lower level log entries are just breadcrumbs, higher level are events
    if (
        [
            Sentry.Severity.Info,
            Sentry.Severity.Log,
            Sentry.Severity.Debug,
        ].indexOf(severity) !== -1
    ) {
        Sentry.addBreadcrumb({
            category: 'ui',
            message,
            level: severity,
        })
    } else {
        Sentry.captureMessage(message, severity)
    }
}

function debug(message: string, ...props: any) {
    log(Sentry.Severity.Debug, message, ...props)
}

function info(message: string, ...props: any) {
    log(Sentry.Severity.Info, message, ...props)
}

function warning(message: string, ...props: any) {
    log(Sentry.Severity.Warning, message, ...props)
}

function error(message: string, ...props: any) {
    log(Sentry.Severity.Error, message, ...props)
}

export default {
    debug,
    info,
    warning,
    error,
}
