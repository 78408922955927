export const locales = ['nl', 'en']
export type Locale = 'nl' | 'en'

type LocaleMessages = {
    [key: string]: string
}

type Messages = {
    [key in Locale]?: LocaleMessages
}

const messages: Messages = {}

/**
 * Loads and caches messages for the provided locale
 * @param locale The locale to load
 */
export async function loadCatalog(locale: Locale) {
    const loc = locales.find((l: string) => locale.indexOf(l) > -1)

    const localeMessages = await import(
        /* webpackMode: "lazy", webpackChunkName: "i18n-[request]" */
        `../../translations/${loc}.json`
    )
    messages[locale] = localeMessages
    return messages
}

/**
 * Gets messages form the messages cache using locale
 * @param locale The selecter locale
 */
export function getMessages(locale: Locale): LocaleMessages | undefined {
    if (!locale) {
        return
    }
    return messages[locale]
}

/**
 * Get a default locale based of browser locale and
 * the ?lang query string
 */
export function getDefaultLocale(): Locale {
    const browserLanguage = navigator.language.split('-')[0]

    const qs = new URLSearchParams(document.location.search)
    const qsLang = qs.get('lang')

    const envLocale = qsLang ?? browserLanguage

    const locale = locales.includes(envLocale) ? envLocale : 'nl'

    return locale as Locale
}
