// A lot has no types for now.
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/browser'
import filterEngine from 'redux-storage-decorator-filter'
import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from '@apollo/client'
import {
    connectRouter,
    routerMiddleware,
    RouterState,
} from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

// Sentry is used for tracking js errors and reporting them
// Object.fromEntires polyfill
// eslint-disable-next-line import/order
import './utils/fromEntries'
// eslint-disable-next-line import/order
import * as storage from 'redux-storage'

// Redux storage is used for caching redux state offline
import createEngine from 'redux-storage-engine-localstorage'

import { createBrowserHistory } from 'history'

import { FormStateMap, reducer as formReducer } from 'redux-form'
import URLSearchParams from 'url-search-params'

import { applyMiddleware, combineReducers, createStore } from 'redux'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'rc-slider/assets/index.css'

import * as selectors from 'redux/selectors'
import { hideModal } from 'redux/modal'

import LoadingIndicator from 'shared/LoadingIndicator/LoadingIndicator'
import logger from 'utils/logger'
import appVersion from 'utils/appVersion'

import { getDefaultLocale, loadCatalog } from 'shared/i18n/locale'

import AuthService from 'features/Auth/AuthService'

import { organizationIdMiddleware } from 'features/Organization/organizationIdMiddleware'

import {
    getAppPickerStateFromStorage,
    getFeatureFlagsFromStorage,
    getTokensFromStorage,
    writeOverrideTokenToStorage,
} from 'persistCache'

import reducers from './redux/reducers'

import { cache as apolloCache } from './cache'

import analytics, { apolloTrackingLink } from './analytics'
import * as serviceWorker from './serviceWorker'

import { AppQuery } from './data/queries/pack/App'

import client, { authLink } from './client'

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_ENV,
        release: appVersion.full,
    })
}

export interface ReduxState {
    app: any
    router: RouterState
    form: FormStateMap

    [key: string]: any
}

export const history = createBrowserHistory()

// Setup redux

const storeCacheKey = 'hipex-pack'
const versionedStoreCacheKey = `${storeCacheKey}-${appVersion.simple}`
const stateStorageEngine = filterEngine(createEngine(versionedStoreCacheKey), [
    ['app', 'tour'],
    ['form'],
])
const appReducer = combineReducers({
    app: reducers as any,
    router: connectRouter(history),
    form: formReducer,
})

// Clear local storage when version changes, but keep authentication data
Object.entries(localStorage).forEach(([key]) => {
    if (key.startsWith(storeCacheKey) && key !== versionedStoreCacheKey) {
        try {
            const oldStoreData = JSON.parse(localStorage[key])
            const authData =
                oldStoreData && oldStoreData.app && oldStoreData.app.auth
            if (authData) {
                // It's possible that authData contains stale data
                // f.e. if the user's email address has been changed in the meantime
                // See https://dev.azure.com/hipex/dev/_workitems/edit/195
                stateStorageEngine.save({ app: { auth: authData } })
            }
        } catch {
            // If the stored data can't be parsed, give up and let the user login again
        }
        delete localStorage[key]
    }
})

const rootReducer = (state: ReduxState | undefined, action: any) =>
    appReducer(state, action)

const reducer = storage.reducer(rootReducer)
const stateStorageMiddleware = storage.createMiddleware(stateStorageEngine)

export const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(thunk),
        applyMiddleware(stateStorageMiddleware),
        applyMiddleware(routerMiddleware(history)),
        applyMiddleware(analytics),
    ),
)

// Setup Apollo
logger.info(
    'Using platform api endpoint',
    process.env.REACT_APP_PLATFORM_API_ENDPOINT,
)

logger.info(
    'Using legacy platform api endpoint',
    process.env.REACT_APP_LEGACY_PLATFORM_API_ENDPOINT,
)

// Setup Legacy GraphQL api

const apolloAuthorizeAfterWareLink = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
        const httpResponse = operation.getContext().response

        // const { token } = (store.getState().app as any).auth
        if (httpResponse.status === 401) {
            AuthService.logout()
        } /* else if (token && isJWTExpired(token)) {
            store.dispatch(triggerAuthUnAuthorized())
        } else {
            const state: ReduxState = store && store.getState()
            const headers = httpResponse.headers
            if (headers.has('Authorization') && !state.app.auth.overrideToken) {
                store.dispatch(
                    triggerAuthTokenNew(headers.get('Authorization')),
                )
            }
        }*/
        return response
    }),
)

const legacyApiLink = new HttpLink({
    uri: process.env.REACT_APP_LEGACY_PLATFORM_API_ENDPOINT,
})

const legacyApolloCache = new InMemoryCache({
    // @TODO: I've disabled this code, and most stuff still seems to work
    // But this requires some more investigation
    // dataIdFromObject: object => {
    //     // Enums dont have a typename
    //     const getObjectType = (object: any) =>
    //         object.__typename ||
    //         object.name ||
    //         object.kind ||
    //         object.title ||
    //         'unknown'
    //     const getObjectId = (object: any) =>
    //         object.id || object.name || object.title || uuid()
    //     return getObjectType(object) + '-' + getObjectId(object)
    // },
})

export const legacyApollo = new ApolloClient({
    link: ApolloLink.from([
        authLink,
        organizationIdMiddleware(),
        apolloAuthorizeAfterWareLink,
        apolloTrackingLink,
        legacyApiLink,
    ]),
    cache: legacyApolloCache,
})

// Populate the store with data from localstorage first, before loading the app
const load = storage.createLoader(stateStorageEngine)

load(store)
    .then(() => {
        apolloCache.writeQuery({
            query: AppQuery,
            data: {
                ...getAppPickerStateFromStorage(),
                featureFlags: getFeatureFlagsFromStorage(),
            },
        })
    })
    .then(async () => {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams && urlParams.get('loginas')) {
            writeOverrideTokenToStorage(urlParams.get('loginas') as string)
        }
    })
    .then(async () => AuthService.checkAuthStatus(getTokensFromStorage()))
    // .then(() => AuthService.onLoad())
    .then(() => {
        history.listen(() => {
            if (selectors.hasModal(store.getState())) {
                store.dispatch(hideModal())
            }
        })

        // We need styling to load before the app does
        const hostTheme = window.location.host.match(/^(\w+)\.?-?pack/)
        const debugTheme =
            process.env.NODE_ENV === 'production'
                ? undefined
                : localStorage.getItem('packDebugTheme')
        const featureFlags = localStorage.getItem('featureFlags')
        let newTheme
        if (featureFlags && featureFlags.indexOf('hipex1') !== -1) {
            newTheme = 'hipex'
        }
        const appTheme = debugTheme ?? newTheme ?? hostTheme?.[1] ?? 'hipex2'
        const appNode = document.getElementById('app')

        function loadAppWithTheme(theme: string) {
            // Lazy load the RootComponent, this allows for a much smaller entry JS file and
            // allows us to show a loader. The first chunk will include ReactDOM and Apollo
            // wich are quite large already.
            const RootComponent = React.lazy(() => import('pages/Root'))
            Promise.all([
                import(
                    /* webpackChunkName: "app-styles" */
                    `./scss/themes/${theme}/styles.scss`
                ),
                import(
                    /* webpackChunkName: "app-icons" */
                    `./scss/themes/${theme}/favicon.ico`
                ),
                loadCatalog(getDefaultLocale()),
            ]).then(
                async ([, icon]) => {
                    ReactDOM.render(
                        <Suspense fallback={<LoadingIndicator absolute />}>
                            <RootComponent
                                store={store}
                                history={history}
                                apollo={client}
                                icon={icon.default}
                            />
                        </Suspense>,
                        appNode,
                    )
                },
                () => {
                    // Loading a theme failed.
                    // If loading hipex failed, something is seriously wrong
                    if (theme === 'hipex2') {
                        if (appNode)
                            appNode.textContent =
                                'Loading the app failed, please try again. Are you offline?'
                        throw new Error('Could not load any theme files.')
                    }

                    // Load default
                    loadAppWithTheme('hipex2')
                },
            )
        }

        loadAppWithTheme(appTheme)
    })

// function showUpdate(registration: ServiceWorkerRegistration) {
//     notifySuccess({
//         message:
//             'A new version of Pack is installed. You will be up-to-date the next time you open pack, or click here to update the app',
//         title: '',
//         autoClose: false,
//         onClick() {
//             const waitingWorker = registration.waiting
//             if (waitingWorker) {
//                 waitingWorker.postMessage({
//                     type: 'SKIP_WAITING',
//                 })
//             }
//         },
//     })
// }

// serviceWorker.register({
//     onSuccess(registration) {
//         logger.info(`Service worker ${registration.waiting?.state}`)

//         const waitingWorker = registration.waiting
//         if (waitingWorker) {
//             waitingWorker.addEventListener('statechange', (e) => {
//                 if (waitingWorker.state === 'activated') {
//                     logger.info('Update available, will reload now.')
//                     window.location.reload()
//                 }
//             })
//         }
//     },
//     onUpdate(registration) {
//         logger.info(
//             `Update is available, current version is ${appVersion.full}.`,
//         )
//         showUpdate(registration)
//     },
// })

// navigator.serviceWorker.getRegistration().then((reg) => {
//     // Debug info for improving update experience
//     logger.debug('Registration status', reg?.waiting?.state)
//     // When a hard-reload is performed, the service worker wouldn't have a
//     // controller. But we need it for authentication.
//     if (reg?.active && !navigator.serviceWorker.controller) {
//         // Perform a soft reload to load everything from the SW and get
//         // a consistent set of resources.
//         window.location.reload()
//     }
// })

serviceWorker.unregister()
