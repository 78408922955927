import { ApolloLink } from '@apollo/client'

import {
    HIPEX_ORGANIZATION_IGNORED_HEADER_KEY,
    HIPEX_ORGANIZATION_HEADER_KEY,
} from 'constants/index'

export const organizationIdMiddleware = () =>
    new ApolloLink((operation, forward) => {
        const organisationId = localStorage.getItem(
            HIPEX_ORGANIZATION_HEADER_KEY,
        )
        const organizationIgnoredValue = localStorage.getItem(
            HIPEX_ORGANIZATION_IGNORED_HEADER_KEY,
        )

        if (organisationId) {
            operation.setContext(({ headers = {} }: any) => ({
                headers: {
                    ...headers,
                    'x-hipex-organisation-id': `${organisationId}`,
                    ...(organizationIgnoredValue && {
                        'x-hipex-organisation-ignore': organizationIgnoredValue,
                    }),
                },
            }))
        }

        return forward(operation)
    })
