import { track } from 'analytics'

const WIZARD_SET_STEP = 'WIZARD_SET_STEP'
const WIZARD_RESET = 'WIZARD_RESET'

const initialWizardState = {
    activeStep: 1,
    highestActiveStep: 1,
}

export const getWizardState = (state, identifier) =>
    state[identifier] || initialWizardState

const newWizardState = (identifier, state, newWizardState) => {
    const newState = {}

    const currentState = getWizardState(state, identifier)

    newState[identifier] = {
        ...currentState,
        ...newWizardState(currentState),
    }

    return newState
}

export const setStep = (identifier, step = 1) => ({
    type: WIZARD_SET_STEP,
    identifier,
    step,
})

export const reset = (identifier) => ({
    type: WIZARD_RESET,
    identifier,
})

export default (state = {}, action) => {
    switch (action.type) {
        case WIZARD_SET_STEP:
            const wizardState = newWizardState(
                action.identifier,
                state,
                (newState) => ({
                    activeStep: action.step,
                    highestActiveStep: Math.max(
                        newState.highestActiveStep,
                        action.step,
                    ),
                }),
            )

            track('application_wizard_step', { step: action.step })

            return {
                ...state,
                ...wizardState,
            }
        case WIZARD_RESET:
            const newState = {}
            newState[action.identifier] = initialWizardState

            return {
                ...state,
                ...newState,
            }
        default:
            return state
    }
}
