const STORE_QUERY_IN_STORE = 'STORE_QUERY_IN_STORE'

export const storeQueryInStore = (key, result) => ({
    type: STORE_QUERY_IN_STORE,
    key,
    result,
})

export default (state = null, action) => {
    switch (action.type) {
        case STORE_QUERY_IN_STORE:
            const newState = {}
            newState[action.key] = action.result

            return {
                ...state,
                ...newState,
            }
        default:
            return state
    }
}
