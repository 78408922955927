export const ACTION_TOGGLE_MINIMIZED = 'ACTION_TOGGLE_MINIMIZED'
export const ACTION_TOGGLE_MENU = 'ACTION_TOGGLE_MENU'
export const ACTION_HAS_SERVER = 'ACTION_HAS_SERVERS'
export const ACTION_HAS_DOMAIN = 'ACTION_HAS_DOMAINS'

export const toggleMinimized = () => ({
    type: ACTION_TOGGLE_MINIMIZED,
})

export const toggleMenu = () => ({
    type: ACTION_TOGGLE_MENU,
})

export const setHasServer = (state) => ({
    type: ACTION_HAS_SERVER,
    state: state,
})

export const setHasDomain = (state) => ({
    type: ACTION_HAS_DOMAIN,
    state: state,
})

const initialState = {
    minimized: false,
    menuToggled: false,
    hasServer: false,
    hasDomain: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TOGGLE_MINIMIZED: {
            const { minimized } = state
            return { ...state, minimized: !minimized }
        }
        case ACTION_TOGGLE_MENU: {
            const { menuToggled } = state
            return { ...state, menuToggled: !menuToggled }
        }
        case ACTION_HAS_SERVER:
            return { ...state, hasServer: action.state }
        case ACTION_HAS_DOMAIN:
            return { ...state, hasDomain: action.state }
        case '@@router/LOCATION_CHANGE':
            // Close menu bar on link click
            return { ...state, menuToggled: false }
        default:
            return state
    }
}

export const isSidebarMinimized = (state) => state.minimized

export const isMenuToggled = (state) => state.menuToggled
