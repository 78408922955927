const DETAILS_LIST_SELECT_ITEM = 'DETAILS_LIST_SELECT_ITEM'
const DETAILS_LIST_RESET_ITEM = 'DETAILS_LIST_RESET_ITEM'

export const selectItem = (identifier, item) => ({
    type: DETAILS_LIST_SELECT_ITEM,
    identifier,
    item,
})

export const resetItem = (identifier) => ({
    type: DETAILS_LIST_RESET_ITEM,
    identifier,
})

const initialListState = {
    selectedItem: null,
}

export const getListState = (state, identifier) =>
    state[identifier] || initialListState

const listState = (identifier, state, newListState) => {
    const newState = {}

    newState[identifier] = {
        ...getListState(state, identifier),
        ...newListState,
    }

    return newState
}

export default (state = {}, action) => {
    switch (action.type) {
        case DETAILS_LIST_SELECT_ITEM:
            return {
                ...state,
                ...listState(action.identifier, state, {
                    selectedItem: action.item,
                }),
            }
        case DETAILS_LIST_RESET_ITEM:
            const newState = {}
            newState[action.identifier] = initialListState

            return {
                ...state,
                ...newState,
            }
        default:
            return state
    }
}
