import { InMemoryCache } from '@apollo/client'

import decode from 'jwt-decode'

import {
    writeFeatureFlagsToStorage,
    writeStoredAppPickerState,
    writeTokensToStorage,
} from 'persistCache'

export const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                featureFlags: {
                    read(flags): string[] {
                        return flags || []
                    },
                    merge(
                        existing: string[] = [],
                        incoming: string | string[],
                    ) {
                        if (incoming.length === 0) return

                        const newArray = [...existing]
                        if (Array.isArray(incoming)) {
                            newArray.push(...incoming)
                        } else {
                            if (newArray.includes(incoming)) {
                                newArray.splice(newArray.indexOf(incoming), 1)
                            } else {
                                newArray.push(incoming)
                            }
                        }

                        writeFeatureFlagsToStorage(newArray)

                        return newArray
                    },
                },
                currentDomainId: {
                    read(domainId) {
                        return domainId || null
                    },
                    merge(_, newState) {
                        writeStoredAppPickerState(null, {
                            currentDomainId: newState,
                        })

                        return newState
                    },
                },
                currentServerId: {
                    read(serverId) {
                        return serverId || null
                    },
                    merge(_, newState) {
                        writeStoredAppPickerState(null, {
                            currentServerId: newState,
                        })

                        return newState
                    },
                },
                appPickerScope: {
                    read(scope) {
                        return scope || 'none'
                    },
                    merge(_, newState) {
                        writeStoredAppPickerState(null, {
                            appPickerScope: newState,
                        })

                        return newState
                    },
                },
                appPickerStateSwitch: {
                    read(state) {
                        return state || false
                    },
                },
                account: {
                    read(state) {
                        return state || null
                    },
                    merge(_, newState) {
                        if (!newState) return null

                        if (typeof newState === 'string') {
                            return decode(newState)
                        } else if (typeof newState === 'object') {
                            return decode(newState.authToken)
                        }
                        return newState
                    },
                },
                tokens: {
                    read(state) {
                        return state || null
                    },
                    merge(_, newState) {
                        writeTokensToStorage(newState)

                        return newState
                    },
                },
            },
        },
    },
})
