import { combineReducers } from 'redux'

import modal from './modal'
import navigation from './navigation'
import locale from './locale'
import wizard from './wizard'
import detailsList from './detailsList'
import route from './route'
import featureFlags from './featureFlags'
import tour from './tour'

const rootReducer = combineReducers({
    modal,
    locale,
    wizard,
    detailsList,
    route,
    navigation,
    featureFlags,
    tour,
})

export default rootReducer
