export default function fromEntries<T>(iterable: Array<[string | number, T]>) {
    if (!iterable || !iterable[Symbol.iterator]) {
        throw new Error(
            'Object.fromEntries() requires a single iterable argument',
        )
    }

    const obj: { [key: string]: T; [key: number]: T } = {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(iterable).forEach((key: any) => {
        const [k, v] = iterable[key]
        obj[k] = v
    })

    return obj
}

if (!Object.fromEntries) {
    Object.defineProperty(Object, 'fromEntries', {
        value: fromEntries,
    })
}
