import packageJson from '../../package.json'
const {
    REACT_APP_COMMIT_SHORT_SHA,
    REACT_APP_COMMIT_REF_NAME,
    NODE_ENV,
} = process.env

let version = packageJson.version

let releaseName = REACT_APP_COMMIT_REF_NAME
if (releaseName === 'staging') releaseName = 'edge'

if (releaseName !== 'master' && REACT_APP_COMMIT_REF_NAME) {
    version = `${version}-${releaseName}+${REACT_APP_COMMIT_SHORT_SHA}`
}

if (NODE_ENV !== 'production') {
    version += `+${NODE_ENV}`
}

export default {
    full: version,
    simple: packageJson.version,
}
