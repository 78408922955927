import { camel } from 'case'

import { TourState } from 'types/TourState'
import { ReduxTourState, TourNames } from 'types/ReduxTourState'

export const ACTION_OPEN_TOUR = 'ACTION_OPEN_TOUR'
export const ACTION_CLOSE_TOUR = 'ACTION_CLOSE_TOUR'
export const ACTION_SET_MAX_STEPS = 'ACTION_SET_MAX_STEPS'
export const ACTION_SET_STEP = 'ACTION_SET_STEP'

export const openTour = (tour: string) => ({
    type: ACTION_OPEN_TOUR,
    payload: { tour: camel(tour) },
})

export const closeTour = (tour: string) => ({
    type: ACTION_CLOSE_TOUR,
    payload: { tour: camel(tour) },
})

export const setCurrentTourStep = (tour: string, stepNo: number) => ({
    type: ACTION_SET_STEP,
    payload: { stepNo, tour: camel(tour) },
})

const tourState: TourState = {
    open: true,
    step: 0,
}

const initialState: ReduxTourState = {
    main: { ...tourState },
    applicationDashboard: { ...tourState },
    applicationSettings: { ...tourState },
    accountSettings: { ...tourState },
    serverDashboard: { ...tourState },
    serverMonitoring: { ...tourState },
    sslManagement: { ...tourState },
    sshManagement: { ...tourState },
    dnsManagement: { ...tourState },
    performanceDashboard: { ...tourState },
}

export default (
    state = initialState,
    action: { type: string; payload: { tour: TourNames; stepNo?: number } },
) => {
    const newState = {
        ...state,
    }

    switch (action.type) {
        case ACTION_OPEN_TOUR: {
            newState[action.payload.tour].open = true
            break
        }
        case ACTION_CLOSE_TOUR: {
            newState[action.payload.tour].open = false
            break
        }
        case ACTION_SET_STEP: {
            newState[action.payload.tour].step = action.payload.stepNo!
            break
        }
    }
    return newState
}

export const isTourOpen = (state: TourState) => state.open
