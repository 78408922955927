import URLSearchParams from 'url-search-params'

export const ACTION_ENABLE_FEATURES = 'ACTION_ENABLE_FEATURES'

export const enableFeatures = (features) => ({
    type: ACTION_ENABLE_FEATURES,
    features,
})

const initialState = {
    forgotPassword: true,
    signUp: true,
}

const getFeaturesToEnable = (features) => {
    const featuresToEnable = {}
    features.split(',').forEach((feat) => {
        featuresToEnable[feat] = true
    })

    return featuresToEnable
}

export default (state = initialState, action) => {
    switch (action.type) {
        case '@@router/LOCATION_CHANGE':
            const {
                payload: { search = '' },
            } = action
            const params = new URLSearchParams(search.substring(1))
            const features = params.get('enableFeatures')

            if (features) {
                return { ...state, ...getFeaturesToEnable(features) }
            }

            return state
        case ACTION_ENABLE_FEATURES:
            return { ...state, ...getFeaturesToEnable(action.features) }
        default:
            return state
    }
}

export const isFeatureEnabled = (state, feature) => {
    if (!Object.keys(state).includes(feature)) return false
    return state[feature]
}
