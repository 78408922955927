import ReactGA from 'react-ga'

import { ApolloLink } from '@apollo/client'

import * as Sentry from '@sentry/browser'

import { snake } from 'case'

import mixpanel from 'mixpanel-browser'

import { useLocation } from 'react-router'
import { useEffect } from 'react'

import { Dispatch, AnyAction, MiddlewareAPI } from 'redux'

let tracking = false

export default (api: MiddlewareAPI) => (next: Dispatch) => (
    action: AnyAction,
) => {
    const start = Date.now()
    try {
        return next(action)
    } finally {
        if (tracking) {
            const time = Date.now() - start

            if (action.meta && action.meta.analytics) {
                const analyticsMeta = action.meta.analytics

                ReactGA.event(analyticsMeta)
                ReactGA.timing({
                    category: analyticsMeta.category,
                    label: analyticsMeta.label,
                    variable: analyticsMeta.action,
                    value: time,
                })
            }
        }
    }
}

export function initTracking() {
    tracking = true
    ReactGA.initialize('UA-87910885-2')
    mixpanel.init('a95f4de179f10e976abc6d17029d055e')
}

export const usePageViewTracking = () => {
    const { pathname } = useLocation()
    useEffect(() => {
        ReactGA.set({
            page: pathname,
        })
        ReactGA.pageview(pathname)
        mixpanel.track('page_view', { pathname })
    }, [pathname])
}

interface Payload {
    [key: string]: string | number | Date
}

export async function track(event: string, payload: Payload) {
    if (process.env.NODE_ENV !== 'development') {
        mixpanel.track(event, payload)
    } else {
        console.log(`simulating track event '${event}' with payload ${payload}`)
    }
}

export const apolloTrackingLink = new ApolloLink((operation, forward) => {
    try {
        if (operation.variables && operation.variables.entity) {
            const {
                username,
                user,
                password,
                passwordConfirm,
                ...trackingParams
            } = operation.variables.entity

            switch (operation.operationName) {
                case 'SSHKey':
                    if (operation.variables.delete) {
                        track('sshkey_removed', trackingParams)
                    } else if (
                        trackingParams.id === undefined &&
                        trackingParams.type
                    ) {
                        track('sshkey_added', trackingParams)
                    } else if (trackingParams.type) {
                        track('sshkey_modified', trackingParams)
                    } else if (trackingParams.id) {
                        track('sshkey_selection_modified', trackingParams)
                    }
                    break

                default:
                    if (operation.variables.delete) {
                        track(
                            `${snake(operation.operationName)}_removed`,
                            trackingParams,
                        )
                    } else if (
                        operation.variables.id ||
                        operation.variables.entity.id
                    ) {
                        track(
                            `${snake(operation.operationName)}_modified`,
                            trackingParams,
                        )
                    } else {
                        track(
                            `${snake(operation.operationName)}_added`,
                            trackingParams,
                        )
                    }
            }
        }
    } catch {
        console.error('error tracking link')
    }

    return forward(operation)
})

let isSentryInitialized = false
export function initSentry(email: string) {
    if (isSentryInitialized) {
        return
    }

    Sentry.setUser({ email: email })

    isSentryInitialized = true
}
