import jwtDecode, { JwtPayload } from 'jwt-decode'

import chatLogout from 'features/ChatWidget/chatLogout'

import client from '../../client'
import { resetCache, writeTokensToStorage } from '../../persistCache'

export enum VerifyTokenStates {
    NoTokens = 'no-tokens',
    Refresh = 'refresh',
    Verified = 'verified',
}

export enum Status {
    Loading = 'loading',
    Authenticated = 'authenticated',
    Unauthenticated = 'unauthenticated',
}

export interface Tokens {
    authToken: string
    refreshToken: string
    overrideToken?: string | null
}

export interface JwtAccount {
    name: string
    email: string
    roles: string[]
    legacy_roles: string[]
    customers: Array<{
        customer: number
        roles: string[]
    }>
    exp: number
    iat: number
    uid: number
    jti: string
}

const refreshQuery = `
    mutation refreshToken($refreshToken: String!) {
        refreshAccount(input: { refreshToken: $refreshToken }) {
            account {
                authToken
                refreshToken
            }
        }
    }
`

class AuthService {
    authenticationStatus = Status.Loading
    updating = false
    listeners: Array<{ index: number; callback: Function }> = []
    currentListenerIndex: number = 0
    tokens: Tokens | null = null
    account?: any

    get status() {
        return this.authenticationStatus
    }

    addListener = (callback: Function) => {
        const index = ++this.currentListenerIndex
        this.listeners.push({ index, callback })
        return index
    }

    removeListener = (index: number) => {
        this.listeners.splice(
            this.listeners.findIndex((l) => l.index === index),
            1,
        )
    }

    // TODO: if another page updates, so should this one
    // onLoad = () => {
    //     window.addEventListener('storage', (e) => {
    //         console.log('storage event', e)
    //         if (e.key === TOKEN_KEY && !e.newValue) {
    //             return this.logout()’
    //         }
    //
    //         if (!this.tokens) {
    //             this.tokens = { authToken: '', overrideToken: null, refreshToken: '' }
    //         }
    //         switch (e.key) {
    //             case TOKEN_KEY:
    //                 this.tokens!.authToken = e.newValue!
    //                 break
    //             case REFRESH_KEY:
    //                 this.tokens!.refreshToken = e.newValue!
    //                 break
    //             case OVERRIDE_TOKEN_KEY:
    //                 this.tokens!.overrideToken = e.newValue
    //                 break
    //         }
    //         this.checkAuthStatus(this.tokens)
    //     })
    // }

    updateListeners = () => {
        this.listeners.forEach((l) => l.callback())
    }

    updateAuthStatus = (tokens: Tokens | null) => {
        const newAuthStatus = tokens?.authToken
            ? Status.Authenticated
            : Status.Unauthenticated
        if (this.authenticationStatus !== newAuthStatus) {
            this.authenticationStatus = newAuthStatus
        }
    }

    setTokens = (tokens: Tokens | null) => {
        this.tokens = tokens
        this.account = this.tokens
            ? jwtDecode<JwtPayload>(this.tokens.authToken)
            : null
        writeTokensToStorage(this.tokens)
        this.updateAuthStatus(this.tokens)
        this.updateListeners()
        return tokens
    }

    login = (tokens: Tokens) => {
        this.setTokens(tokens)
    }

    logout = () => {
        resetCache(client)
        this.setTokens(null)
        chatLogout()
        return null
    }

    forgetTokens = () => {
        this.updateAuthStatus(null)
    }

    stringIsValidJwt = (token: string) => {
        const parts = token.split('.')
        if (parts.length === 0) {
            return false
        }
        return /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/.test(
            token,
        )
    }

    isJWTExpired = (token: string, offset: number = 5 * 60) => {
        const result = jwtDecode<JwtPayload>(token) as {
            exp: number
        }
        const currentTime = Date.now() / 1000
        return result.exp < currentTime + offset
    }

    getTokens = async (): Promise<Tokens | null> => {
        if (this.updating) {
            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (this.updating) return
                    done()
                }, 200)

                const done = () => {
                    clearInterval(interval)
                    resolve(this.tokens)
                }
            })
        }

        return await this.checkAuthStatus(this.tokens)
    }

    checkAuthStatus = async (tokens: Tokens | null = this.tokens) => {
        switch (this.verifyTokens(tokens)) {
            case VerifyTokenStates.Verified:
                this.setTokens(tokens)
                return tokens
            case VerifyTokenStates.Refresh:
                return this.refreshToken(tokens!)
            default:
                // Logout by default
                return this.logout()
        }
    }

    verifyTokens = (tokens: Tokens | null = this.tokens): VerifyTokenStates => {
        if (
            !tokens ||
            !tokens.authToken ||
            !tokens.refreshToken ||
            !this.stringIsValidJwt(tokens.authToken)
        )
            return VerifyTokenStates.NoTokens
        if (this.isJWTExpired(tokens.authToken))
            return VerifyTokenStates.Refresh
        return VerifyTokenStates.Verified
    }

    refreshToken = async (tokens: Tokens) => {
        this.updating = true
        const result = await this.fetchNewToken(tokens)
        this.updating = false
        if (!result) {
            return this.logout()
        } else {
            return this.setTokens(result)
        }
    }

    fetchNewToken = async (tokens: Tokens = this.tokens!) => {
        const response = await fetch(
            process.env.REACT_APP_PLATFORM_API_ENDPOINT || '/graphql',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    operationName: 'refreshToken',
                    variables: {
                        refreshToken: tokens!.refreshToken,
                    },
                    query: refreshQuery,
                }),
            },
        )
        const newTokenResult = await response.json()

        const account = newTokenResult?.data?.refreshAccount?.account as
            | Tokens
            | undefined
        return account ?? null
    }
}

const service = new AuthService()

export default service as AuthService
