import { track } from 'analytics'

import {
    getMessages as getMessagesNew,
    getDefaultLocale,
    loadCatalog,
    locales,
} from 'shared/i18n/locale'

export const ACTION_SET_LOCALE = 'ACTION_SET_LOCALE'

export const setLocale = (locale) => async (dispatch) => {
    await loadCatalog(locale)
    dispatch({
        type: ACTION_SET_LOCALE,
        locale: locale.substr(0, 2),
    })
}

// Get default locale

const initialState = {
    locale: getDefaultLocale(),
    locales: locales,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION_SET_LOCALE:
            track('language_modified', { locale: action.locale })

            return {
                ...state,
                locale: action.locale,
            }
        default:
            return state
    }
}

export const getLocale = (state) => state.locale

export const getLocales = (state) => state.locales

export const getMessages = getMessagesNew
