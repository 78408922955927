import { ApolloClient, gql } from '@apollo/client'

import { Tokens } from './features/Auth/AuthService'

interface AppPickerState {
    appPickerScope: string
    currentDomainId: number | null
    currentServerId: number | null
}

const FEATURE_FLAGS_KEY = 'featureFlags'
const APP_PICKER_STATE_KEY = 'appPickerState'
export const TOKEN_KEY = 'hptoken'
export const REFRESH_KEY = 'hprefresh'
export const OVERRIDE_TOKEN_KEY = 'loginas'

const appPickerState = {
    appPickerScope: 'none',
    currentDomainId: null,
    currentServerId: null,
}

export function getTokensFromStorage(): Tokens | null {
    const tokens = {
        authToken: localStorage.getItem(TOKEN_KEY) as string,
        refreshToken: localStorage.getItem(REFRESH_KEY) as string,
        overrideToken: localStorage.getItem(OVERRIDE_TOKEN_KEY),
    }

    if (!tokens.authToken || !tokens.refreshToken) {
        return null
    } else {
        return tokens
    }
}

export function writeTokensToStorage(tokens: Tokens | null) {
    if (tokens) {
        localStorage.setItem(TOKEN_KEY, tokens?.authToken)
        localStorage.setItem(REFRESH_KEY, tokens?.refreshToken)
    } else {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(REFRESH_KEY)
    }
}

export function getAppPickerStateFromStorage(): AppPickerState {
    const state = localStorage.getItem(APP_PICKER_STATE_KEY)
    if (state) {
        return JSON.parse(state)
    }
    return { ...appPickerState }
}

export function writeStoredAppPickerState(
    state?: AppPickerState | null,
    value?: Partial<AppPickerState>,
): void {
    if (!state) {
        state = getAppPickerStateFromStorage()
    }

    if (value) {
        state = { ...state, ...value }
    }
    localStorage.setItem(APP_PICKER_STATE_KEY, JSON.stringify(state))
}

export function getFeatureFlagsFromStorage(): [] {
    const state = localStorage.getItem(FEATURE_FLAGS_KEY)
    if (state) {
        return JSON.parse(state)
    }
    return []
}

export function writeFeatureFlagsToStorage(flags: string[]): void {
    localStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify(flags))
}

export function writeOverrideTokenToStorage(token: string): void {
    localStorage.setItem(OVERRIDE_TOKEN_KEY, token)
}

export function getOverrideTokenFromStorage(): string | null {
    return localStorage.getItem(OVERRIDE_TOKEN_KEY) || null
}

export function removeOverrideTokenFromStorage() {
    localStorage.removeItem(OVERRIDE_TOKEN_KEY)
}

export function resetCache(client: ApolloClient<any>) {
    localStorage.setItem(APP_PICKER_STATE_KEY, JSON.stringify(appPickerState))
    client.writeQuery({
        query: gql`
            query App {
                currentDomainId
                currentServerId
                appPickerScope
                appPickerStateSwitch
            }
        `,
        data: {
            appPickerStateSwitch: null,
            ...appPickerState,
        },
    })

    removeOverrideTokenFromStorage()
}
