import * as fromModal from './modal'
import * as fromLocale from './locale'
import * as fromNavigation from './navigation'
import * as fromFeatureFlags from './featureFlags'

// Modals

export const hasModal = (state) => fromModal.hasModal(state.app.modal)

export const getCurrentModalView = (state) =>
    fromModal.getCurrentModalView(state.app.modal)

export const getModalComponent = (state) =>
    fromModal.getModalComponent(state.app.modal)

export const getModalProps = (state) => fromModal.getModalProps(state.app.modal)

// Navigation

export const isSidebarMinimized = (state) =>
    fromNavigation.isSidebarMinimized(state.app.navigation)

export const isMenuToggled = (state) =>
    fromNavigation.isMenuToggled(state.app.navigation)
// Localization

export const getLocale = (state) => fromLocale.getLocale(state.app.locale)

export const getLocales = (state) => fromLocale.getLocales(state.app.locale)

export const getLocaleMessages = (state) =>
    fromLocale.getMessages(state.app.locale)

// Feature flags

export const isFeatureEnabled = (state, feature) =>
    fromFeatureFlags.isFeatureEnabled(state.app.featureFlags, feature)
