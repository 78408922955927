import { gql } from '@apollo/client'

export const AppQuery = gql`
    query App {
        featureFlags
        currentDomainId
        currentServerId
        appPickerScope
        appPickerStateSwitch
    }
`
