import { ApolloClient, ApolloLink } from '@apollo/client'

import { createUploadLink } from 'apollo-upload-client'

import { setContext } from '@apollo/client/link/context'

import identifyChat from 'features/ChatWidget/identifyChat'

import { initSentry } from 'analytics'

import { cache as apolloCache } from './cache'
import { organizationIdMiddleware } from './features/Organization/organizationIdMiddleware'

import AuthService from './features/Auth/AuthService'

const resolvers = {}

export const authLink = setContext(async () => {
    const tokens = await AuthService.getTokens()
    if (!tokens) return {}

    const authToken = tokens.overrideToken || tokens.authToken
    if (!authToken) {
        // No changes
        return {}
    }

    const account = AuthService.account

    if (account?.uid && account?.email) {
        const mbId = account.email
        const attrs: any = {
            uid: account.uid,
            email: account.email,
        }

        if (account.name) {
            const regex = /\w+\s\w+(?=\s)|\w+/g
            const name = account.name
            const [firstName, lastName] = name.trim().match(regex)

            attrs.name = account.name
            attrs.firstName = firstName
            attrs.lastName = lastName
        }

        identifyChat(mbId, attrs)
    }
    if (account?.email) {
        initSentry(account.email)
    }

    // If authHandler returns a token, use it in all graphql calls. The token
    // returned from authHandler should be up-to-date and not expired..
    return {
        headers: {
            authorization: `Bearer ${authToken}`,
        },
    }
})

const apolloLinks = (createUploadLink({
    uri: process.env.REACT_APP_PLATFORM_API_ENDPOINT,
}) as unknown) as ApolloLink

const client = new ApolloClient({
    cache: apolloCache,
    link: ApolloLink.from([authLink, organizationIdMiddleware(), apolloLinks]),
    // link: authLink.concat(apolloLinks),
    resolvers,
})

export default client
