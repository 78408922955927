import { Stack } from 'immutable'
const MODAL_SHOW = 'MODAL_SHOW'
const MODAL_HIDE = 'MODAL_HIDE'
const MODAL_PUSH = 'MODAL_PUSH'
const MODAL_POP = 'MODAL_POP'
const MODAL_REPLACE = 'MODAL_REPLACE'

export const showModal = (component, props) => ({
    type: MODAL_SHOW,
    component,
    props,
})

export const hideModal = () => ({
    type: MODAL_HIDE,
})

export const pushModalView = (name) => ({
    type: MODAL_PUSH,
    name,
})

export const popModalView = () => ({
    type: MODAL_POP,
})

export const replaceModalView = (name) => ({
    type: MODAL_REPLACE,
    name,
})

const initialState = {
    modal: null,
    views: new Stack(),
}

export default (state = initialState, action) => {
    switch (action.type) {
        case MODAL_SHOW:
            return {
                modal: {
                    component: action.component,
                    props: action.props,
                },
                views: state.views.clear(),
            }

        case MODAL_HIDE:
            return {
                modal: null,
                views: state.views.clear(),
            }
        case MODAL_POP:
            return {
                ...state,
                views: state.views.pop(),
            }
        case MODAL_PUSH:
            return {
                ...state,
                views: state.views.push(action.name),
            }
        case MODAL_REPLACE:
            return {
                ...state,
                views: state.views.pop().push(action.name),
            }
        default:
            return state
    }
}

export const hasModal = (state) => !!state.modal

export const getModalComponent = (state) => state.modal && state.modal.component

export const getModalProps = (state) => state.modal && state.modal.props

export const getCurrentModalView = (state) => state.views && state.views.peek()
