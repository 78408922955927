import React from 'react'
import classNames from 'classnames'

export interface LoadingIndicatorProps {
    absolute?: boolean
}

const LoadingIndicator = ({ absolute }: LoadingIndicatorProps) => (
    <div
        className={classNames(
            absolute ? 'loader-wrapper-absolute' : 'loader-wrapper',
        )}
    >
        <svg
            version="1.1"
            className="loader"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="243 270 102 90"
            xmlSpace="preserve"
        >
            <g>
                <path
                    className="triangle triangle-1"
                    d="M292.2,298.7L244.6,328.6L244.6,273.8Z"
                />
                <path
                    className="triangle triangle-2"
                    d="M245.1,330.2L292.7,300.3L292.7,355.1Z"
                />
                <path
                    className="triangle triangle-3"
                    d="M294.6,300.3L341.6,330.2L294,355.1Z"
                />
                <path
                    className="triangle triangle-4"
                    d="M342,328.5L295.1,298.7L342.1,273.8L342,328.5Z"
                />
            </g>
        </svg>
    </div>
)

LoadingIndicator.defaultProps = {
    absolute: false,
}

export default LoadingIndicator
